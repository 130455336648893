<template lang="">
  <div class="position-relative div-hover">
    <div
      class="image-div w-100"
      :style="`
        ${backgroundTourUrl ? `background-image: url(${backgroundTourUrl})` : ''};
        height: ${isMobileView ? '100vw' : '600px'};
        max-height: ${isMobileView ? '400px' : ''};
        min-height: ${isMobileView ? '330px' : ''};
        ${isMobileView ? 'border-radius: 16px;' : ''}
      `"
    />

    <div
      style="position: absolute; top: 10%; left: 50%; transform: translate(-50%, 0%); color: white; text-align: center"
      class="text-uppercase fw-900"
    >
      <div
        class="position-relative"
        :style="`font-size: ${isMobileView ? '30px' : '70px'}`"
      >
        Khám phá
      </div>
      <div
        style="font-size: 140px; opacity: 0.7; mix-blend-mode: overlay;"
        :style="`font-size: ${isMobileView ? '60px' : '140px'}`"
        class="text-nowrap"
      >
        thế giới
      </div>
    </div>

    <div
      style="position: absolute; bottom: 5%; left: 50%; transform: translate(-50%, 0%); color: white; text-align: center; backdrop-filter: blur(16px); opacity: 90%"
      :style="`width: ${isMobileView ? '90%' : '70%'}`"
      class="px-1 py-75 rounded-lg text-wrap"
    >
      Dòng tour này Airdata hướng đến mục tiêu bất cứ Du Khách nào cũng có cơ hội đi du lịch với mức chi phí tiết kiệm nhất. Các điểm tham quan và dịch vụ chọn lọc phù hợp với ngân sách của Du Khách nhưng vẫn đảm bảo hành trình du lịch đầy đủ và thú vị.
    </div>
  </div>
</template>
<script>
import env from '@/libs/env'

export default {
  setup() {
    return {
      backgroundTourUrl: env.backgroundTourUrl,
    }
  },
}
</script>
<style lang="scss" scoped>
.div-hover {
  cursor: pointer;
  .image-div {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 60px;
    transition: all 0.5s ease-in-out;
    animation: animatedBackground 10s linear infinite alternate-reverse;
  }
  &:hover {
    .image-div {
      animation-play-state: paused;
    }
  }
}

@keyframes animatedBackground {
  from {
    background-size: 150%;

  }
  to {
    background-size: 100%;
  }
}

 // mobile = 130%
@media screen and (max-width: 767px) {
  @keyframes animatedBackground {
    from {
      background-size: 230%;
    }
    to {
      background-size: 180%;
    }
  }
}
</style>
